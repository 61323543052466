import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { StockMarketChart, BondMarketChart, CommoditiesChart, CurrenciesChart, EconomyChart } from "../../../components/widgets/indicators";
import styled from "styled-components";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const Chart = styled.div`
    float: right;
    max-width: min(500px, 50%);
    margin-left: ${props => props.theme.spacings.normal};
`;
export const _frontmatter = {};
const layoutProps = {
  Chart,
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/dashboard/" text="Dashboard" mdxType="Breadcrumbs" /> 
    <Aside mdxType="Aside">
      <p><strong parentName="p">{` See also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/dashboard/market-vane/"
          }}>{`Market Vane signal`}</a></li>
      </ul>
    </Aside>
    <p>{`Indicators provide deeper insights into the mechanisms driving stock prices, including market sentiment, borrowing costs, commodity prices, exchange rates, and economic growth. In addition, they also represent the return of buy & hold investments, the most essential benchmark for tactical portfolios.`}</p>
    <div id="stocks" />
    <h2>{`U.S. Stock Market`}</h2>
    <Chart mdxType="Chart"><StockMarketChart mdxType="StockMarketChart" /></Chart>
    <p>{`Since U.S. stocks tend to be the most important driver of returns in many investor’s portfolios, the health of the U.S. stock market is of significant relevance.`}</p>
    <p>{`The `}<strong parentName="p">{`Total Return`}</strong>{` graph shows the appreciation of an investment in a capitalization-weighted large cap stock index over the past ten months, assuming reinvestment of all cash dividends. This return is equivalent to buying and holding an index, such as the S&P 500. `}</p>
    <p>{`The `}<strong parentName="p">{`50 Day Moving Average`}</strong>{` and the `}<strong parentName="p">{`200 Day Moving Average`}</strong>{` are important levels of resistance and support. Comparing the index to these levels helps identify long term trends. When the total return index crosses below its 200 Day Moving Average, the stock market is considered to be in a bear market territory. Similarly, many traders consider it a bearish indicator, often called a death cross, when the 50 Day Moving Average crosses below the 200 Day Moving Average.`}</p>
    <p>{`The `}<strong parentName="p">{`30 Day Implied Volatility`}</strong>{` graph shows a measure of annualized market volatility based on the pricing of at-the-money options with 30 days to expiration. This measure is forward looking and often higher than historical volatility. An example of such an index is the `}<a parentName="p" {...{
        "href": "https://www.cboe.com/tradable_products/vix/"
      }}>{`VIX`}</a>{`. Similarly, the `}<strong parentName="p">{`1 Year Implied Volatility`}</strong>{` graph shows a measure of annualized market volatility based on the pricing of at-the-money options with one year to expiration. This measure serves as a useful benchmark for the 30 Day Implied Volatility. Typically, implied volatility increases with longer observed periods and 30 day volatility ranges around 75% of 1 year volatility. However, in times of market fear, the 30 day volatility can reach or even exceed the 1 year volatility, providing investors with a valuable signal for market uncertainty.`}</p>
    <div id="bonds" />
    <h2>{`U.S. Bond Market`}</h2>
    <Chart mdxType="Chart"><BondMarketChart mdxType="BondMarketChart" /></Chart>
    <p>{`Bonds serve as the fixed-income safety net in many investor’s portfolios. When turbulent stock markets initiate a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Flight-to-quality"
      }}>{`flight to quality`}</a>{`, bonds often see an increase in value, resulting in an overall negative correlation to the stock market. Therefore, the health of the bond market is of high significance to investors.`}</p>
    <p>{`The `}<strong parentName="p">{`Aggregate Bond Market Index`}</strong>{` is a good proxy for the bonds many investors hold in their portfolios. Similar to the stock market, the `}<strong parentName="p">{`50 and 200 Day Moving Averages`}</strong>{` are useful tools to identify long term market trends.`}</p>
    <p>{`When comparing bonds, investors typically look at their yields rather than their price. While the Fed sets the `}<strong parentName="p">{`Federal Funds Rate`}</strong>{`, the yields for longer term `}<strong parentName="p">{`Treasury Bills`}</strong>{` and `}<strong parentName="p">{`Treasury Bonds`}</strong>{` are set through the market mechanics of supply and demand. It is important to notice that rising yields typically lead to falling bond prices, as described by the formulas of `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Bond_valuation"
      }}>{`bond valuation`}</a>{`. The difference between the market yields, called the `}<strong parentName="p">{`yield spread`}</strong>{`, is of particular interest. Typically yields for longer term or lower quality bonds should be higher than those for bonds with shorter term or higher quality. Many investors consider an inversion of the yield curve a precursor to recession.`}</p>
    <p><strong parentName="p">{`Investment grade corporate bonds`}</strong>{` provide higher yields than treasuries at the expense of higher credit risk. This asset class typically has a very low correlation to the stock market, although it may increase in times of recession.`}</p>
    <div id="commodities" />
    <h2>{`Commodities`}</h2>
    <Chart mdxType="Chart"><CommoditiesChart mdxType="CommoditiesChart" /></Chart>
    <p>{`In economics, a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Commodity"
      }}>{`commodity`}</a>{` is a good that has full or substantial fungibility. In other words, the market treats the goods as equivalent, regardless of who produced them. In investing, commodities create exposure to physical goods, which acts as a hedge against inflation.`}</p>
    <p>{`The `}<strong parentName="p">{`commodity index`}</strong>{` represents the overall commodity market. This includes all sorts of basic materials including metals, foods, energy and other raw materials; and can be seen as a proxy for industrial consumption. As with the stock market moving averages, the commodity 50 day and 200 day `}<strong parentName="p">{`moving averages`}</strong>{` are an indication of longer term trends.`}</p>
    <p>{`By segmenting the commodities market into sub indices, we can gain additional insight. For this chart, we have chosen `}<strong parentName="p">{`agriculture`}</strong>{` as a proxy for soft commodities. Further, we are showing `}<strong parentName="p">{`energy`}</strong>{`, which is mostly dominated by the price of oil and gas. Next, we are showing `}<strong parentName="p">{`industrial metals`}</strong>{`, which include copper, iron, and aluminum. And finally, we have `}<strong parentName="p">{`precious metals`}</strong>{`, such as gold.`}</p>
    <div id="forex" />
    <h2>{`U.S. Dollar`}</h2>
    <Chart mdxType="Chart"><CurrenciesChart mdxType="CurrenciesChart" /></Chart>
    <p>{`The U.S. Dollar is the most widely used currency in international transactions. As a free floating currency, the price of the U.S. Dollar also represents the confidence in the U.S. economy and the credit rating of the U.S. government.`}</p>
    <p>{`The `}<strong parentName="p">{`U.S. Dollar Index`}</strong>{` measures the value of the U.S. Dollar against a basket of major currencies. In the chart, we show the `}<strong parentName="p">{`Euro`}</strong>{`, the `}<strong parentName="p">{`British Pound`}</strong>{`, the `}<strong parentName="p">{`Japanese Yen`}</strong>{`, and the `}<strong parentName="p">{`Swiss Franc`}</strong>{`.`}</p>
    <p>{`A rising exchange rate of the dollar makes U.S. goods more expensive abroad, which is a burden for U.S. exports. Similarly, a falling dollar makes imported goods more expensive in the U.S., possibly resulting in higher consumer prices and inflation. This is where the Federal Reserve's monetary policy with the goal of maintaining price stability is linked with the currency exchange rate.`}</p>
    <div id="economy" />
    <h2>{`U.S. Economy`}</h2>
    <Chart mdxType="Chart"><EconomyChart mdxType="EconomyChart" /></Chart>
    <p>{`Even though the economy and the markets are only loosely related, they are ultimately headed towards a common destination. Therefore economic indicators help investors anticipate the future.`}</p>
    <p>{`The Big Four indicators represent the main drivers of the U.S. economy: `}<strong parentName="p">{`employment, production, sales, and income`}</strong>{`. In a healthy economy, we expect all four indicators to continuously rise. While it is comforting to see how these indicators rise, we often find limited information on the slope of these indicators.`}</p>
    <p>{`However, when the economy approaches a recession, the indicators first stall, and then start to decrease. Therefore, declines from a previous all-time high, or `}<strong parentName="p">{`off peak values`}</strong>{`, are useful leading indicators for a recession.`}</p>
    <p>{`The Big Four set of indicators is simple, transparent, and effective, which is the reason why they are so often quoted. We recommend also looking at the `}<a parentName="p" {...{
        "href": "https://www.chicagofed.org/research/data/cfnai/current-data"
      }}>{`Chicago Fed’s National Activity Index`}</a>{`, which we like because of its broad coverage.`}</p>
    <h2>{`Learn More`}</h2>
    <p><em parentName="p">{`TuringTrader`}</em>{`'s `}<a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}>{`Market Vane`}</a>{` signal combines inputs from various markets with macro-economic indicators to provide a simple, accurate and timely signal for the most basic of all investment decisions: risk-on vs. risk-off.`}</p>
    <ButtonPrimary text="check out market vane" to="/dashboard/market-vane/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      